<template>
  <div class="operator-roles-wrapper">
    <div v-for="(role, index) in op_roles" :key="index" class="operator-role-description">
      <h3><img class="role-description" :src="require(`@/assets/images/icons/${role.image}`)" alt="description-icon">{{ $t(`converted.${role.name}`) }}</h3>
      <p>{{ $t(`converted.${role.desc}`) }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "RoomAdminRoles",
  data() {
    return {
      op_roles: [
        {
          image: 'friend.svg',
          name: 'friends',
          desc: 'are_able_to_send_private_messages_within_the_room_using_the_msg_command'
        },
        {
          image: 'moderator.svg',
          name: 'moderators',
          desc: 'are_members_who_are_given_special_abilities_to_kick_punish_members'
        },
        {
          image: 'admin.svg',
          name: 'admins',
          desc: 'can_designate_members_as_friends_or_moderators_in_room_manage_room_settings'
        },
        {
          image: 'owner.svg',
          name: 'owner',
          desc: 'only_room_owner_can_add_members_as_admin'
        },
      ],
    };
  }
}
</script>

<style lang="scss" scoped>
.operator-roles-wrapper {
  color: #545454;
  line-height: 19px;
  font-size: 14px;
  margin: 20px 0;
}

.operator-role-description {
  max-width: 460px;
  padding: 12px 22px 20px;
  box-sizing: border-box;
  text-align: left;

  h3 {
    font-weight: 700;
  }

  p {
    padding-left: 31px;
  }
}
img.role-description {
  width: 19px;
  margin-right: 12px;
  vertical-align: sub;
  display: inline;
}
</style>
